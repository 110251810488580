.text-divider {
	--text-divider-gap: 1rem;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.text-divider::after {
	align-items: center;
	content: "";
	height: 2px;
	background-color: black;
	flex-grow: 1;
}

.text-divider::before {
	margin-right: var(--text-divider-gap);
}

.text-divider::after {
	margin-left: var(--text-divider-gap);
}
