.rootElement {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.contentElement {
	display: flex;
	flex: auto;
	flex: 1;
	min-height: calc(100vh - 8rem);
}

.headerElement {
	top: 0;
	width: 100%;
	min-height: 5rem;
	max-height: 5rem;
}

.footerElement {
	bottom: 0;
	width: 100%;
	min-height: 2rem;
	max-height: 2rem;
}
