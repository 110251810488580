/* SelectButton CSS Start*/
.p-selectbutton > div {
	background-color: white !important;
	color: black;
}

.p-selectbutton > .p-highlight {
	background-color: #06b6d4 !important;
	color: white;
}

.p-selectbutton > .p-focus {
	outline-offset: 2px !important;
}

.p-selectbutton > div:focus {
	box-shadow: 0 0 0 2px #fff, 0 0 0 4px #71e7fb, 0 1px 2px 0 rgb(0, 0, 0);
}
/* SelectButton CSS End*/
